import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../context/auth";
import { Button } from "react-bootstrap";
import axios from "axios";

const ShowOffers = () => {
    const { logout } = useContext(AuthContext);
    const [offers, setOffers] = useState([]);
    const [onlyVideo, setOnlyVideo] = useState(false);
    const [onlyImage, setOnlyImage] = useState(false);
    const [productLineId, setProductLineId] = useState("");
    const [offerAdvertiserId, setOfferAdvertiserId] = useState("");
    const [offerIndex, setOfferIndex] = useState(0);
    const [timeoutID, setTimeoutID] = useState(null);
    const [playingAdvertise, setPlayingAdvertise] = useState(false);

    const videoPlayer = useRef();
    // console.log('inicio', playingAdvertise)
    useEffect(() => {
        const load = async () => {
            await getOffers();
        };
        load();
    }, []);

    useEffect(() => {
        let intervalId = setInterval(() => {
            if (!playingAdvertise) {
                setPlayingAdvertise(true);
                clearTimeout(timeoutID);
                renderRxyVideo();
            }
            return () => clearInterval(intervalId)
        }, 300000);

        return () => clearInterval(intervalId);
    }, [playingAdvertise]);

    useEffect(() => {
        renderItem()
    }, [offers, playingAdvertise]);

    const handlePrevious = () => {
        const previousIndex = (offerIndex - 1 + offers.length) % offers.length;
        setOfferIndex(previousIndex);
        renderItem(previousIndex);
    };
    const handleNext = () => {
        const nextIndex = (offerIndex + 1) % offers.length;
        setOfferIndex(nextIndex);
        renderItem(nextIndex);
    };

    const renderRxyVideo = () => {
        console.log()
        clearTimeout(timeoutID)
        const rxyVideo = "anuncio-rxy.mp4";
        videoPlayer.current.pause();
        videoPlayer.current.controls = true;
        videoPlayer.current.src = rxyVideo;
        videoPlayer.current.muted = false;
        videoPlayer.current.load();
        videoPlayer.current.oncanplay = function () {
            videoPlayer.current.play();
        };
        // videoPlayer.current.play();
        videoPlayer.current.onended = function () {
            setOfferIndex((offerIndex + 1) % offers.length);
            renderItem((offerIndex + 1) % offers.length);
            setPlayingAdvertise(false);
        };
    };

    const renderItem = (offerIndexParam = null) => {
        if (offers.length <= 0) {
            return;
        }
        const offerIndexToUse = offerIndexParam || offerIndex;
        const offer = offers[offerIndexToUse];
        if (!offer) {
            return;
        }
        var isVideo =
            offer.tipo_midia === "video" &&
            offer.midia !== undefined &&
            offer.midia !== null &&
            offer.midia !== "";
        if (isVideo) {
            clearTimeout(timeoutID);
            if (offer.midia.startsWith("/")) {
                offer.midia = offer.midia
            } else {
                // Faça algo se a offer.midia não começar com "/"
                offer.midia = "/" + offer.midia; // Adiciona "/" ao início da offer.midia
            }
            var videoUrl = "https://rxy.com.br/assets/ofertas" + offer.midia;
            console.log(videoUrl)
            videoPlayer.current.pause();
            videoPlayer.current.controls = true;
            videoPlayer.current.src = videoUrl;
            videoPlayer.current.load();
            // videoPlayer.current.play();
            videoPlayer.current.oncanplay = function () {
                videoPlayer.current.play();
            };        
            videoPlayer.current.onended = function () {
                setOfferIndex((offerIndexToUse + 1) % offers.length);
                renderItem((offerIndexToUse + 1) % offers.length);
            };
        } else {
            clearTimeout(timeoutID);
            var imageUrl = "https://rxy.com.br/assets/ofertas" + offer.midia;
            videoPlayer.current.controls = false;
            videoPlayer.current.src = imageUrl;
            videoPlayer.current.setAttribute("poster", imageUrl);
            setTimeoutID(
                setTimeout(function () {
                    setOfferIndex((offerIndexToUse + 1) % offers.length);
                    console.log('teste maroto', playingAdvertise)
                    if(!playingAdvertise){
                        renderItem((offerIndexToUse + 1) % offers.length);
                    } else {
                        // alert('chegou aqui')
                        renderRxyVideo()
                    }
                }, 10000)
            );
        }
    };

    const getOffers = async (
        twentyFourHoursAgo = false,
        specificDateFilter = null,
        offerAdvertiserId = null,
        productLineId = null,
        onlyImage = false,
        onlyVideo = false
    ) => {
        try {
            let url = "https://tv.rxy.com.br/api/offers?1=1"
            // let url = "http://localhost:3050/offers?1=1";
            if (twentyFourHoursAgo) {
                url += "&twentyFourHoursAgo=true";
            }
            if (specificDateFilter) {
                url += `&specificDate=${specificDateFilter}`;
            }
            if (offerAdvertiserId) {
                url += `&concorrenteId=${offerAdvertiserId}`;
            }
            if (productLineId) {
                url += `&productLineId=${productLineId}`;
            }
            if (onlyVideo) {
                url += "&onlyVideo=true";
            }
            if (onlyImage) {
                url += "&onlyImage=true";
            }
            const token = localStorage.getItem("token");
            const options = {
                headers: {
                    Authorization: "Bearer " + token, // replace token with your actual token
                },
            };
            const responseOffers = await axios.get(url, options);
            console.log('teste', url)
            const data = responseOffers.data.offers;
            setOffers(data);
        } catch (error) {
            console.log(error)
            alert(
                "Problemas ao carregar as ofertas! Por favor contate o suporte."
            );
        }
    };

    function getOnlyVideos(event) {
        setOnlyVideo(!onlyVideo);
    }

    function getOnlyImage(event) {
        setOnlyImage(!onlyImage);
    }

    function selectConcorrente(event) {
        setOfferAdvertiserId(event.target.value);
    }

    function selectProductLine(event) {
        setProductLineId(event.target.value);
    }

    function clearFilter() {
        window.location.reload();
    }

    function applyFilter() {
        getOffers(
            false,
            null,
            offerAdvertiserId,
            productLineId,
            onlyImage,
            onlyVideo
        );
    }
    console.log('aqui', offerIndex)
    return (
        <>
        <nav class="navbar navbar-expand-lg bg-light">
        
            <div
                style={{
                    height: "80px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                }}
                className="container"
            >
                <a class="navbar-brand" style={{fontWeight:"bold"}} href="#">Monitor de Ofertas</a>
                <div>
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="only-video"
                        onChange={getOnlyVideos}
                        checked={onlyVideo}
                    />
                    <label style={{ textTransform: "uppercase" }}>
                        {" "}
                        &nbsp; Apenas Videos
                    </label>
                    <span className="checkmark"></span>
                </div>
                <div>
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="only-photo"
                        onChange={getOnlyImage}
                        checked={onlyImage}
                    />
                    <label style={{ textTransform: "uppercase" }}>
                        {" "}
                        &nbsp; Apenas Fotos{" "}
                    </label>
                </div>

                {/* <!-- <div style="margin-left: 10px;">
                    <input style="float:left;" className="form-check-input" type="checkbox" id="twenty-four-hours-ago-checkbox"/>
                    <label style="text-transform: uppercase;"> Ultimas 24 Horas</label>
                    <span className="checkmark"></span>
                </div>
                <div>
                    <label style="text-transform: uppercase;">Dia Especifico </label>
                    <input  type="date" id="specific-date" />
                </div> --> */}
                <div>
                    <select
                        class="form-select"
                        value={offerAdvertiserId}
                        onChange={selectConcorrente}
                        // className="custom-select"
                        // id="advertiser-id"
                    >
                        <option value="">Anunciantes</option>
                        <option value="4">Casas Bahia</option>
                        <option value="6">Eletrokasa</option>
                        <option value="7">Martinello</option>
                        <option value="9">Novo Mundo</option>
                        <option value="11">Havan</option>
                        <option value="12">Bemol</option>
                        <option value="16">Ideal móveis</option>
                        <option value="17">Novalar</option>
                        <option value="18">Móveis MM</option>
                        <option value="21">Magazine luiza</option>
                        <option value="29">Darom Móveis</option>
                        <option value="32">Riachuelo</option>
                        <option value="33">Americanas</option>
                        <option value="44">Pernambucanas</option>
                    </select>
                </div>
                <div>
                    <select
                        class="form-select"
                        value={productLineId}
                        onChange={selectProductLine}
                        // className="custom-select"
                        // id="product-line-id"
                    >
                        <option selected value="">
                            Linha Produto
                        </option>
                        <option value="16">Áudio e Vídeo</option>
                        <option value="17">Eletro</option>
                        <option value="18">Móveis</option>
                        <option value="19">Informática</option>
                        <option value="20">Telecom</option>
                        <option value="21">Portáteis</option>
                        <option value="22">Lazer</option>
                        <option value="23">Util. e Presentes</option>
                        <option value="24">Brinquedos</option>
                        <option value="25">Ventilação</option>
                        <option value="26">Climatização</option>
                        <option value="27">Colchões</option>
                        <option value="28">Estofados</option>
                    </select>
                </div>
                <div>
                    <Button
                        type="button"
                        className="btn btn-success"
                        id="apply-filters-button"
                        onClick={() => applyFilter()}
                    >
                        Aplicar filtros
                    </Button>
                    &nbsp;
                    <Button
                        type="button"
                        className="btn btn-primary"
                        id="clean-filters-button"
                        onClick={() => clearFilter()}
                    >
                        Limpar Filtros
                    </Button>
                </div>
                <div>
                    <Button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => logout()}
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </nav>
            <div
                className="container"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                }}
            >
                <Button
                    type="button"
                    className="btn btn-secondary"
                    id="previus-offer"
                    onClick={handlePrevious}
                >
                    Anterior
                </Button>
                <Button
                    type="button"
                    className="btn btn-success"
                    id="next-offer"
                    onClick={handleNext}
                >
                    Próximo
                </Button>
            </div>
            <br />
            <div
                style={{ height: "1080px", width: "1920", marginTop: "2px" }}
                id="video-container"
            >
                <video
                    style={{ height: "100%", width: "100%" }}
                    ref={videoPlayer}
                    autoPlay
                    fullscreen="true"
                    onEnded={() => renderItem([offerIndex])}
                ></video>
            </div>
        </>
    );
};
export default ShowOffers;
