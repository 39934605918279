import React, {useState, useContext} from "react";
import { AuthContext } from "../../context/auth";
import './style.css';

const Login = () => {
    const { login } = useContext(AuthContext)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password)
    }

    return (
        <div className="main-login">
            <div className="right-login">
                <div className="card-login">
                    <h1>Login</h1>
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="textfield">
                            <label htmlFor="email">E-mail</label>
                            <input 
                                type="email" 
                                name="email"
                                placeholder="Email"
                                id="email" 
                                value={email}
                                onChange ={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="textfield">
                            <label htmlFor="password">Senha</label>
                            <input 
                                type="password" 
                                name="password"
                                placeholder="Senha" 
                                id="password"
                                value={password}
                                onChange ={(e)=> setPassword(e.target.value)}
                            />
                        </div>
                        <div className="button">
                            <button className="btn-login" type="submit">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login