import axios from "axios";

export const api = axios.create({
    baseURL: "https://tv.rxy.com.br/api",
    // baseURL:"https://e87c-177-86-71-115.sa.ngrok.io"
});

export const createSession = async(email, password) => {
    return api.post("/login", {email, password})
}
