import  { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate, Switch } from "react-router-dom";
import { AuthProvider, AuthContext } from "./context/auth";
import Login from "./pages/login";
import ShowOffers from "./pages/offers";

const AppRoutes = () => {
    const Private = ({children}) => {
        const { authenticated } = useContext(AuthContext)

        if(!authenticated) {
            return <Navigate to="/login" />
        }
        return children
    }

    return(
        <BrowserRouter>
            <AuthProvider>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/" element={<Private><ShowOffers /></Private>} />
            </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default AppRoutes